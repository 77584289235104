.ui.sx-table {
    border-radius: 4px;
    border: 0;
    border: 1px solid $color-border;
    width: 100%;
    overflow: auto;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
        border-radius: 3px;
    }

    tr>td:first-of-type {
        width: 337px;
        border: 1px solid $color-border;
        border-right: 0 !important;
        background-color: $color-light-grey;
        border-top: 0;
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;

        @media screen and (max-width: 768px) {
            width: 244px;
        }
    }

    td {
        font-size: 16px !important;
        vertical-align: top;
        border-color: $color-border !important;
        border: 1px solid $color-border;
        background-color: $color-white;
        border-top: 0 !important;
        border-right: 0;
        color: $color-black-2;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 300;
        padding: 14px 16px;

        @media screen and (max-width: 768px) {
            font-size: 14px !important;
            font-weight: 400;
            padding: 12px 13px;
        }
    }

    tr:last-of-type td {
        border-bottom: 0;
    }
}

.sx-table-cont {
    margin-bottom: 32px !important;
    overflow: auto;

    &.with-shadows {
        cursor: url('../images/scroll.svg'), auto;
    }

    &--normal td {
        text-align: left !important;
    }
}

.sr {
    display: none !important;
}

/* shadows */

.shadowzone {
    position: relative;
    overflow: hidden;
    clear: both;
}

.shadowzone-start,
.shadowzone-end {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0;
    width: 30px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
}

.shadowzone-end {
    left: auto;
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.1)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
}

/*  */
.sx-table-padded {
    padding: 16px 0 !important;
}

.ui.sx-table-summary {
    border-radius: 4px;
    background-color: $color-grey;
    border: 0;
    padding: 16px 16px 0;

    .sx-table-cont {
        margin: 0 !important;
    }

    .grid {
        padding-top: 16px;
    }
}

.ui.table.sx-table-headless {
    border: 0 !important;
    background: transparent;
    margin: 0;
    border-collapse: separate;

    tr {
        th {
            border: 0;
            color: $color-black-2;
            font-family: 'Akrobat', sans-serif;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.55px;
            background-color: $color-grey;
            border: 0 !important;
        }

        td {
            border: 1px solid $color-border !important;
            border-right: 0 !important;
            border-bottom: 0 !important;
            line-height: 24px;
            background-color: $color-white;

            &:first-of-type {
                padding: 10px 12px 10px 19px !important;
            }

            &:last-of-type {
                border-right: 1px solid $color-border !important;
            }

            &::after {
                content: "";
                display: table;
                clear: both;
            }
        }

        &:last-of-type {
            td {
                border-bottom: 1px solid $color-border !important;
            }
        }
    }
}

.sx-text-labels-cont {
    display: flex;
    align-items: center;
    justify-content: center;

    .sx-text-label {
        min-width: 24px;
        border-radius: 2px;
        background-color: #e3ecfa;
        color: $color-primary;
        font-family: Roboto;
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 4px 0px;
    }
}

.sx-cell-80 {
    width: 80% !important;
}

@media (min-width: 1024px) {
    .sx-table-2-cont {
        margin-bottom: 0px!important;
    }
}




@media (max-width: 910px) {
    .ui.table:last-child {
        margin-bottom: 0 !important;
    }
}

.sx-table-2 {
    border: 0 !important;
    margin-bottom: 24px !important;
    overflow: auto;
    width: 100%;
    white-space: nowrap;

    tr:nth-child(even) {
        td {
            background-color: $color-light-grey-2;
        }
    }

    thead {
        th {
            border-radius: 4px 4px 0 0;
            background-color: $color-secondary !important;
            color: $color-white;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            padding: 12px 16px !important;
            color: $color-white !important;
            font-weight: 400 !important;
            border: 0 !important;
            border-right: 1px solid rgba($color-border, 0.24) !important;
        }
    }

    tbody {
        td {
            padding: 14px 16px !important;
            border: 0 !important;
            border-right: 1px solid $color-border !important;
            border-bottom: 1px solid $color-border !important;
            color: $color-black-2;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 300;

            &:first-of-type {
                border-left: 1px solid $color-border !important;
            }

            .ui.calendar {
                margin-right: 12px;
            }
        }
    }

}