.sx-dropdown-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 912px;
    width: 100%;
    min-height: 240px;
    max-height: 240px;
    height: 100%;
    border-radius: 4px;
    background-color: $color-grey;
    margin: 0 auto;
    margin-bottom: 30px;

    .field {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 510px;

        @media (max-width: $tablet-v) {
            padding: 0 30px;
        }
    }

    .ui.fluid.dropdown {
        // min-width: 285px;
        max-width: 415px;
        margin-right: 8px;
        border-radius: 2px;
        border: 1px solid $color-light-grey-3;
        background-color: $color-white;
        padding: 15px 10px;
        flex: 1 80%;

        @media (max-width: $tablet-v) {
            margin: 0 0 10px 0;
            max-width: 100%;
        }
    }

    .ui.button {
        background-color: $color-grey-3 !important;
        border-radius: 2px;
        color: $color-white;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.43px;
        padding: 15px 16px;
        margin: 0;

        @media (max-width: $tablet-v) {
            width: 100%;
            margin: 0;
        }

    }

    label {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 6px;
        flex: 1 100%;
    }
    .ui.fluid.dropdown>.dropdown.icon {
        background-image: url(../images/blue-arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        width: 12px;
        height: 9px;
        display: inline-block;
        transform: translate(-100%, 200%) rotate(270deg);
    }
}