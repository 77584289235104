.sx-header {
    .ui.secondary.menu {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        height: 2.85714286em;
        border-bottom: 1px solid rgba(34, 36, 38, .15);

        @media (min-width: 910px) {
            padding-left: 155px;
        }

        @media (max-width: 910px) {
            background-color: $color-grey;
            display: none;
        }
    }

    .logo {
        margin: 0;
        display: flex;
        max-width: 149px;
        align-items: flex-end;
        width: 100%;
        margin: 0 12px 0 12px;
    }

    .site-logo-image {
        max-width: 149px;

        @media (max-width: 910px) {
            margin: 10px 0px 14px 0px;
        }
    }

    .sx-top-items {
        display: flex;
        justify-content: space-between;

        @media (max-width: 910px) {
            background-color: $color-primary;
            align-items: center;
        }
    }

    .ui.menu {
        margin: 0;
    }

    .sx-menu-toggle {
        display: block;
        position: relative;
        margin-right: 8px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: transparent;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        transition: all ease 0.4s;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 22px;
            transition: all ease 0.3s;
            left: 9px;
        }

        &:before {
            border-bottom: 2px solid #fff;
            border-top: 2px solid #fff;
            height: 20px;
            top: 10px;
        }

        &:after {
            height: 2px;
            background-color: #fff;
            top: 19px;
        }

        &.active {
            &:before {
                transform: rotate(45deg);
                border-bottom: 0px;
                border-top: 0px;
                height: 2px;
                background-color: #fff;
                top: 19px;
            }

            &:after {
                transform: rotate(135deg);
            }
        }

        @media (min-width: 910px) {
            display: none;
        }
    }

    .ui.menu a.item {
        transition: all 0.4s ease;

        &:hover {
            background-color: $color-secondary;
            color: $color-white;
        }
    }

    .ui.menu:first-child {
        border: none;
        box-shadow: none;
    }

    .ui.menu .menu {
        border-radius: 0;
        background-color: $color-primary;

        @media (max-width: 910px) {
            background-color: $color-white;
            width: 100%;
            height: 100%;
        }
    }

    .ui.accordion:not(.styled) .title~.content:not(.ui):last-child {
        padding: 0;
    }

    .ui.vertical.accordion.menu {
        @media (min-width: 910px) {
            display: none;
        }
    }

    .ui.menu .ui.dropdown .menu>.item {
        transition: all ease 0.3s !important;
    }

    .ui.menu .ui.dropdown .menu>.item:hover {
        border-radius: 0;
    }

    .ui.pointing.menu .active.item:after {
        background-image: url(../images/active-menu-after.svg) !important;
        background-position: center !important;
        width: 18px;
        height: 12px;
        background-repeat: no-repeat !important;
        background-color: transparent;

        transform: translateX(0%) translateY(0%) rotate(0);
        margin: 0;
        border: 0;
        bottom: 0px !important;
        top: auto !important;

        @media (max-width: 910px) {
            display: none;
        }
    }

    i.icon {
        margin-left: 30px;

        @media (max-width: 1300px) {
            margin-left: 4px;
        }

        @media (max-width: 910px) {
            margin-left: 0;
            margin-right: 4px !important;
        }

        &.user:before {
            content: '';
            background-image: url(../images/user-icon.svg) !important;
            background-position: center !important;
            width: 16px;
            height: 18px;
            display: block;
            background-repeat: no-repeat !important;

            @media (max-width: 910px) {
                background-image: url(../images/user-icon-mobile.svg) !important;
            }
        }
    }

    .ui.circular.label {
        padding: 0.219em !important;
        margin-bottom: 7px;
    }

    .ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
        display: flex;
        justify-content: space-between;
    }

    .sx-text {
        opacity: 0.64;
        color: $color-white;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .text-2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .ui.dropdown.item {
        border-radius: 0px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 12px 10px;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 40px;
        width: 100%;
        position: relative;
    }

    .ui.dropdown>.text-1>.icon {
        margin: 0;
    }

    .tagline {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        margin-right: 2px;
    }

    .ui.dropdown.item:hover {
        border-radius: 0px !important;
        background-color: $color-secondary;
        color: $color-white !important;
    }

    .ui.menu .item>i.icon {
        margin: 0;
        position: absolute;
        right: 14px;
        bottom: 40%;
    }

    .ui.dropdown .menu>.item>.label {
        min-width: 2em;
        min-height: 2em;
        padding: .5em !important;
        line-height: 1em;
        text-align: center;
        border-radius: 500rem;
        margin: 0;
        margin-left: auto;
    }

    .language-selector,
    .mobile-language-selector {
        color: $color-white;
        display: inline-flex;
        align-items: center;
        padding: 0px 12px;
        border-left: 1px solid rgba(255, 255, 255, 0.12);
        background-color: $color-primary;
        transition: all ease 0.4s;

        @media (min-width: 910px) and (max-width: 1024px) {
            padding: 0 6px;
        }
    }

    .mobile-language-selector {
        display: none;
        margin-left: auto;
        margin-right: 15px;
        border: 0;

        @media (max-width: 910px) {
            display: block;
            padding: 27px 12px;
        }
    }

    .ui.selection.dropdown {
        border-radius: 0;
        color: $color-white;
        background-color: $color-primary;
        padding: 1.04em 0.1em 0.785714em 1em;
        min-width: 10em;
        transition: all 0.4s ease;
        display: inline-flex;
        align-items: center;
        border-color: rgba(255, 255, 255, 0.12);
        border-top: none;
        border-bottom: none;

        @media (max-width: 1300px) {
            padding: 1.04em 0.1em 0.785714em 0.7em;
            min-width: 7em;
        }

        @media (max-width: 910px) {
            background-color: $color-white;
            color: $color-black-2;
            width: 100%;
        }

        @media (min-width: 910px) {
            &:hover {
                background-color: $color-secondary;
            }
        }
    }

    .dropdown:last-child .menu {
        z-index: 9999;
    }

    .ui.selection.active.dropdown .menu {
        background-color: $color-white;
        min-height: 5.76rem;
    }

    .ui.default.dropdown:not(.button)>.text,
    .ui.dropdown:not(.button)>.default.text {
        color: $color-white;

        @media (max-width: 910px) {
            color: $color-black-2;
        }
    }

    .ui.dropdown>.text {
        font-family: "Akrobat";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.8px;

        @media (max-width: 1300px) {
            font-size: 14px;
        }
    }

    .ui.active.selection.dropdown {
        background-color: $color-secondary;
        color: $color-white;

        &>.default.text {
            color: $color-white;
            font-weight: 400;
            user-select: none;
        }
    }

    .ui.horizontal.menu.active {
        background-color: $color-white;

        &.ui.menu:not(.vertical) .item {
            color: $color-black-2;
        }

        i.icon.user:before {
            background-image: url(../images/user-icon-mobile.svg) !important;
        }

        .sx-text {
            color: $color-black-2;
        }

        .ui.dropdown.item>.angle.down.icon {
            background-image: url(../images/grey-arrow-bigger.svg);

        }
    }

    .ui[class*="right labeled"].icon.button:hover>.icon {
        background-color: $color-secondary;
        padding: 11px;
        width: 0 !important;
    }

    .ui[class*="right labeled"].icon.button,
    .ui[class*="right labeled"].icon.button>.icon {
        background-color: $color-white;
        transition: all ease 0.4s;

        &:hover {
            background-color: $color-secondary;
            color: $color-white;
            opacity: 1;
            margin: 5px 15px 5px 0;
            width: 85px;
        }
    }

    .ui[class*="right labeled"].icon.button {
        width: 80px;
        margin-right: 15px;
        opacity: 0.54;
        color: $color-black-2;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.24px;
    }

    .ui[class*="right labeled"].icon.button>.icon {
        background-image: url(../images/log-out-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        padding: 5px;
        display: inline-block;
    }

    .ui.horizontal.menu.active:hover {
        &.ui.menu:not(.vertical) .item {
            color: $color-white;
        }

        i.icon.user:before {
            background-image: url(../images/user-icon.svg) !important;
        }

        .sx-text {
            color: $color-white;
        }

        .ui.dropdown.item>.angle.down.icon {
            background-image: url(../images/white-arrow.svg);

        }
    }


    @media (min-width: 910px) and (max-width: 1024px) {
        .ui.horizontal.menu {
            width: 100%;
        }
    }

    .ui.menu:not(.vertical) .item {
        color: $color-white;
        font-family: "Akrobat";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.8px;

        @media (max-width: 1300px) {
            font-size: 14px;
        }

        @media (min-width: 910px) and (max-width: 1024px) {
            padding: 10px 5px 10px 10px;
        }

        @media (max-width: 910px) {
            color: $color-black-2;
            padding: 0;
        }
    }

    .ui.accordion.menu .item .title {
        @media (max-width: 910px) {
            padding: 15px;
            border-bottom: 1px solid #DCE6F0;
        }
    }

    .ui.accordion .active.content {
        @media (max-width: 910px) {
            background-color: $color-grey;
        }
    }

    .ui.stackable.menu:not(.vertical) .item:nth-of-type(1) {
        border-radius: 22px 0 0;

        @media (max-width: 910px) {
            border-radius: 0;
            width: 100%;
        }
    }

    @media (min-width: 910px) {
        .ui.menu:not(.vertical) .item:last-of-type {
            margin-right: auto;
        }
    }

    .ui.menu .item>i.right.icon {
        right: 15px;
    }

    .ui.selection.dropdown>.angle.down.icon,
    .ui.dropdown.item>.angle.down.icon {
        background-image: url(../images/white-arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        width: 12px;
        height: 9px;
        display: inline-block;
        transform: rotate(0deg);
    }

    .ui.accordion.menu .item .title>.dropdown.icon {
        background-image: url(../images/down-arrow-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        width: 18px;
        height: 18px;
        display: inline-block;
        transform: rotate(0deg);
        transition: ease all 0.3s;
    }

    .ui.accordion.menu .item .active.title>.dropdown.icon {
        transform: rotate(180deg) !important;
    }

    i.icon.arrow.right:before {
        background-image: url(../images/grey-right-arrow.svg) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        font-size: 0;
        transform: rotate(90deg);
        content: '' !important;
        padding: 9px;
        display: block;
    }

    .ui.secondary.menu .item {
        color: $color-blue-1;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.24px;
    }

    .ui.secondary.menu .active.item {
        color: $color-red;
        background-color: $color-white;

        &:hover {
            background-color: $color-secondary;
            color: $color-white;
        }
    }

    .ui.secondary.vertical.menu {
        &>.item {
            @media (max-width: 910px) {
                padding-left: 0;
                padding: 15px;
                border-bottom: 1px solid $color-grey-2;
                border-radius: 0 !important;
                background-color: $color-grey;
                margin: 0;
                color: $color-black-2;
            }
        }
    }

    .ui.vertical.menu .item .menu a.item:hover {
        @media (max-width: 910px) {
            background-color: $color-white;
            color: $color-black-2;
        }
    }

    .ui.vertical.menu .item .menu .active.item {
        @media (max-width: 910px) {
            color: $color-white;
            background-color: $color-secondary;
        }

    }

    .ui.vertical.accordion.menu {
        @media (max-width: 910px) {
            display: none;
            height: 75vh;
            overflow-y: auto;

        }
    }

    .ui.menu:not(.vertical) .right.item,
    .ui.menu:not(.vertical) .right.menu {
        background-color: $color-primary;
        border-radius: 22px 0 0;
        margin-left: 0 !important;
        flex: 1 1 0%;
        display: flex;

        @media (max-width: 910px) {
            border-radius: 0;
            background-color: $color-white;
            display: none !important;
            margin: 0 !important;
        }
    }

    @media only screen and (max-width: 910px) {
        .ui.stackable.menu {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 1300px) {
        .ui.menu .item {
            font-size: 14px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .ui.selection.dropdown {
            min-width: 6em;
            padding: 13px 5px;
        }
    }
}