.is-ie .ui.modal.sx-modal {
    opacity: 0 !important;
}

.is-ie .ui.modal.sx-modal.visible,
.is-ie .ui.modal.visible {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    transform-origin: center center !important;
    opacity: 1 !important;
}

.ui.modal.sx-modal,
.ui.modal {
    margin: 0 auto !important;

    >.header:not(.ui) {
        padding: 0 !important;
    }

    .DTE_Form_Buttons {
        .primary {
            background-color: $color-blue-2;
        }

        .negative {
            background-color: #52ae31 !important;
        }

        .button {
            height: 36px;
            border-radius: 4px;
            color: $color-white;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.4px;
            background-color: #66c32e;
        }
    }

    .header,
    .DTE_Header_Content {
        padding: 19px 45px 19px 16px;
        color: $color-black-2;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        border-bottom: 1px solid #f5f5f5;
    }

    .close.icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid rgba($color-black-2, 0.12);
        top: 16px !important;
        right: 16px !important;
        font-size: 0;
        background-image: url('../images/icons/close-modal.svg');
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-position: center center;
        padding: 0;
        margin: 0;
        opacity: 1;
        cursor: pointer;
        position: absolute;
        z-index: 1;
    }

    .content {
        padding: 24px 16px;
    }

    .description {
        color: $color-black-2;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 300;
    }

    .actions {
        background-color: $color-grey;
        padding: 8px 16px !important;
        border-top: 0;
        text-align: right;
        border-radius: 0 0 4px 4px;

        .button {
            margin-bottom: 0 !important;

        }
    }

    .field {
        label {
            color: $color-black-2;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 15px;
        }

        textarea {
            max-width: 432px;
            width: 100%;
            margin: 0 auto;
            display: block;
            border-radius: 2px;
            border: 1px solid $color-light-grey-3;
            background-color: $color-white;
            color: rgba($color-black-2, 0.48);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            // Text style for "Добавет"
            letter-spacing: 0.4px;

            @media (max-width: 910px) {
                font-size: 16px;
            }
        }

        .sx-input {
            max-width: 432px;
            width: 100%;
            margin: 0 auto;
            display: block;
            border-radius: 2px;
            border: 1px solid $color-light-grey-3;
            background-color: $color-white;
            padding: 0 !important;
            height: auto;
            border: 0;

            .sx-button.button {
                padding: 0 !important;
            }

            div {
                position: relative;
            }

            .icon {
                position: absolute;
            }

            input,
            .sx-input-file {
                height: 36px;
                padding: 8px 38px 8px 10px !important;
                width: 100%;
                border-radius: 2px;
                border: 1px solid $color-light-grey-3;
                margin-bottom: 6px;
                display: block;
            }

            .sx-button {
                font-weight: 400;
            }

            .clear {
                display: flex;
                justify-content: space-between;
            }

        }

        .ui.error.message {
            max-width: 432px;
            margin: 16px auto 0;
        }

        .sx-size {
            color: rgba(#000000, 0.54);
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            display: inline-block;
        }

        .ui.horizontal.sx-horizontal-divider {
            color: rgba(#000000, 0.2);
            font-family: 'Roboto', sans-serif;
            font-size: 11px;
            font-weight: 400;
            margin: 8px auto;
            text-align: center;

            &::after,
            &::before {
                display: none;
            }
        }

        .sx-dropzone {
            max-width: 432px;
            width: 100%;
            height: 156px;
            border-radius: 4px;
            border: 1px solid $color-light-grey-3;
            background-color: #e3ecfa;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            .dz-preview {
                padding: 16px;

                >* {
                    line-height: 1.4;
                }
            }

            .icon {
                display: block;
                margin-bottom: 24px;
            }

            &-message {
                color: rgba($color-blue-1, 0.54);
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            &.dz-started {

                .dz-error-message,
                .sx-dropzone-message,
                .dz-success-mark,
                .dz-error-mark {
                    display: none;
                }
            }
        }
    }
}

.ui.DTE {
    select {
        appearance: none;
        background-size: 8px 5px;
        background-image: url(../images/icons/arrow-select.svg);
        background-repeat: no-repeat;
        background-position: 98% center;
    }
    input[type=text],
    select {
        min-height: 36px;
        border-radius: 2px;
        border: 1px solid #e8ecf0;
        padding: 0 !important;
        padding: 8px 38px 8px 10px !important;
        color: #222222;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.4px;

        @media (max-width: 910px) {
            font-size: 16px;
        }
    }
    .DTE_Form_Content {
        @media screen and (max-width: 768px) {

            label.field {
                justify-content: flex-start !important;
                font-family: 'Roboto', sans-serif !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                letter-spacing: 0.46px;
                margin-bottom: 12px !important;
                padding: 0 !important;

            }





            .inline.fields {
                margin-bottom: 16px !important;
            }

            .wide.field {
                padding: 0 !important;
            }
        }
    }
}