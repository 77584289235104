// Colors
$color-white: #fff;
$color-black: #000;
$color-black-2: #222222;
$color-primary: #66CC33;
$color-blue-1: #173070;
$color-blue-2: #0072bc;
$color-secondary: #db2828;
$color-grey: #f7f7f7;
$color-grey-2: #DCE6F0;
$color-grey-3: #7f7f7f;
$color-grey-4: #edf0f7;
$color-light-grey: #f6f8fa;
$color-light-grey-2: #fafafa;
$color-light-grey-3: #e8ecf0;
$color-light-grey-4: #f5f5f5;
$color-red: #52ae31;
$color-border: #e6eaee;


/* media queries vars */
$desktop: 1200px;
$tablet-h: 1024px;
$tablet-v: 768px;
$phone-h: 640px;
$phone-v: 480px;
$phone-s: 359px;