.main-footer {
    &__top {
        display: flex;
        justify-content: flex-start;
        background-color: $color-border;
        width: 100%;

        &-inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            min-height: 48px;

            @media (max-width: $phone-h) {
                flex-wrap: wrap;
            }

        }

        &-left {
            margin-right: 30px;
            @media (max-width: $tablet-v) {
                margin: 5px 14px 3px 0px;
            }
        }

        &-right {

            @media (max-width: $tablet-v) {
                margin: 5px 0 3px 0px;
            }
        }
    }

    &__bottom {
        background-color: $color-primary;

        &-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 40px;
            align-items: center;

            @media (max-width: $tablet-v) {
                flex-wrap: wrap;
            }

            @media (max-width: $phone-v) {
                height: 100%;
                flex-wrap: wrap;
            }
        }
    }

    &-text {
        opacity: 0.48;
        color: $color-black-2;
        font-family: Akrobat;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.55px;
        margin-right: 9px;

        @media (max-width: $phone-v) {
            font-size: 16px;
        }
    }

    &-link {

        margin-right: 8px;

        &::after {
            content: "\003B";
            opacity: 0.48;
            color: $color-black-2;
        }

        &-text {
            font-family: Akrobat;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.55px;
            transition: all ease 0.4s;

            &:hover {
                color: $color-red;
            }

            @media (max-width: $phone-v) {
                font-size: 16px;
            }
        }

        &:last-child {
            margin-right: 0;

            &::after {
                display: none;
            }
        }
    }
}

.copyright {
    color: $color-white;
    transition: all ease 0.4s;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;

    &--left {
        opacity: 0.8;

        @media (max-width: $tablet-v) {
            margin: 3px 0;
            padding-right: 30px;
        }

    }

    &-link {
        opacity: 1;
        color: $color-white;
        text-decoration: none;

        &:hover {
            color: $color-white !important;
        }
    }

    &-text {
        opacity: 0.8;
    }

    &--studiox {
        font-size: 14px;

        @media (max-width: $tablet-v) {
            margin: 3px 0;
        }
    }

    &--studiox {
        display: inline-block;

        & a:after {
            content: '';
            width: 0px;
            height: 1px;
            display: block;
            background: $color-white;
            transition: 400ms;
        }

        & a:hover:after {
            width: 100%;
        }

    }
}