a {
  transition: ease all 0.4s;
}

.dataTables_info {
  font-size: 16px;
}

.ui.celled.table tr td {
  font-size: 14px;
}

.ui.form input[type=search]:hover {
  transition: all ease 0.4s;
  border-color: #85b7d9;
}

.length-menu__span,
.paginate__arrow {
  font-size: 0;
  display: none;
}

.ui.menu .item>img:not(.ui) {
  width: 5px;
}

.paginate__arrow {
  &-1 {
    &--img {
      transform: rotate(0deg);
    }
  }

  &-2 {
    &--img {
      transform: rotate(180deg);
    }
  }
}

div.dataTables_wrapper div.dataTables_info {
  color: $color-black-2;
  font-size: 12px;
  font-weight: 400;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  display: flex;
  margin-left: 5px;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.length-menu {
  &__span {
    &--text {
      max-width: 51px;
      width: 100%;
      transform: translate(6px, 8px);
      position: absolute;
      line-height: 11px;
      font-size: 14px;
    }

    &--text-2 {
      padding: 0 2px;
    }

    &--text-3 {
      display: block;
    }

    &--text,
    &--text-2,
    &--text-3 {

      opacity: 0.54;
    }
  }
}

.sx-dataTable {
  margin-bottom: 48px;

  table.dataTable tr.even,
  table.dataTable tr.selected.even {
    background-color: $color-light-grey-2 !important;
  }

  table.dataTable tbody>tr.selected,
  table.dataTable tbody>tr>.selected,
  .dataTable.stripe tbody>tr.odd.selected,
  .dataTable.stripe tbody>tr.odd>.selected,
  .dataTable.display tbody>tr.odd.selected,
  .dataTable.display tbody>tr.odd>.selected,
  .dataTable.order-column tbody>tr.selected>td,
  .dataTable.order-column tbody>tr.selected>.sorting_2,
  .dataTable.order-column tbody>tr.selected>.sorting_3,
  .dataTable.order-column tbody>tr>.selected,
  .dataTable.display tbody>tr.selected>td,
  .dataTable.display tbody>tr.selected>.sorting_2,
  .dataTable.display tbody>tr.selected>.sorting_3,
  .dataTable.display tbody>tr>.selected {
    background: initial !important;
  }

  table.dataTable tr.selected th.select-checkbox.sorting_asc::after {
    content: "\f0de";
    margin-top: 0;
    margin-left: -4px;
    text-align: 0;
    text-shadow: none;
  }

  table.dataTable tr.selected th.select-checkbox.sorting_desc::after {
    content: "\f0dd";
    margin-top: 0;
    margin-left: -4px;
    text-align: 0;
    text-shadow: none;
  }
  table.dataTable tr.selected th.select-checkbox:after {
    display: none;
    content: '';
  }
  table.dataTable tr.selected {
    th.select-checkbox {
      .sx-checkbox-like {
        background-image: url('../images/icons/checked-white.svg');
      }
    }
  }

  table.dataTable th.select-checkbox {
    position: relative;



    .sx-checkbox-like {
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
      border: 0;
      background-image: url('../images/icons/unchecked-white.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }


  }

  table.dataTable tbody td.select-checkbox {
    font-size: 0 !important;
  }

  table.dataTable tbody td.select-checkbox:before,
  table.dataTable tbody th.select-checkbox:before,
    {
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    margin-left: -8px;
    border: 0;
    background-image: url('../images/icons/unchecked.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  table.dataTable tr.selected td.select-checkbox:before,
  table.dataTable tr.selected th.select-checkbox:before,
    {
    content: '';
    background-image: url('../images/icons/checked.svg');
  }

  &-wrapper {
    overflow: auto;

    &.scrollable {
      cursor: url(../images/scroll.svg), auto;
    }
  }

  .data-table_length label span {
    color: red;
  }

  .ui.celled.table>tr td,
  .ui.celled.table>tr th {
    border-left: 1px solid $color-border;
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_length .ui.selection.dropdown {
    background-color: $color-white;
    color: $color-black-2;
  }

  .ui.dropdown>.text,
  .ui.dropdown .menu .item {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
  }

  .ui.dropdown .menu>.item {
    padding: 9px !important;
  }

  .table.dataTable.table thead th.sorting,
  table.dataTable.table thead th.sorting_asc,
  table.dataTable.table thead th.sorting_desc,
  table.dataTable.table thead td.sorting,
  table.dataTable.table thead td.sorting_asc,
  table.dataTable.table thead td.sorting_desc,
  table.dataTable.table thead th.sorting_disabled {
    transition: all ease 0.4s;
    background-color: $color-secondary;
    color: $color-white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.sx-dataTable-popup {
    margin-bottom: 0;

    .table.dataTable.table thead th.sorting,
    table.dataTable.table thead th.sorting_asc,
    table.dataTable.table thead th.sorting_desc,
    table.dataTable.table thead td.sorting,
    table.dataTable.table thead td.sorting_asc,
    table.dataTable.table thead td.sorting_desc,
    table.dataTable.table thead th.sorting_disabled {
      background-color: $color-blue-2;
    }

    th {
      border-left: 1px solid rgba($color-border, 0.24) !important;
    }

    .bottom {
      justify-content: center !important;
    }
  }

  .table.dataTable.table thead th.sorting:hover,
  table.dataTable.table thead th.sorting_asc:hover,
  table.dataTable.table thead th.sorting_desc:hover,
  table.dataTable.table thead td.sorting:hover,
  table.dataTable.table thead td.sorting_asc:hover,
  table.dataTable.table thead td.sorting_desc:hover {
    color: $color-white;
    background-color: #0072bc;

  }

  .ui.celled.table {
    border-bottom: 1px solid $color-border;

    tr {
      th {
        &:first-of-type {
          border-left: 1px solid $color-border;
        }

        &:last-of-type {
          border-right: 1px solid $color-border;
        }
      }

      tbody {
        border-bottom: 1px solid $color-border;
      }

      td {
        color: $color-black-2;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 300;
        padding: 13px 20px 12px;

        &:first-of-type {
          border-left: 1px solid $color-border;
        }

        &:last-of-type {
          border-right: 1px solid $color-border;
        }
      }
    }
  }

  table.dataTable.table tfoot {
    border-top: 16px solid $color-white;
    border-bottom: 1px solid $color-border;

    strong {
      font-weight: 700;
    }

    td {

      background-color: $color-light-grey-2;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: $color-border;
      }
    }
  }

  // Cell colors
  .ui.table.dataTable.display tbody>tr.selected>td,
  .ui.dataTable.table tbody tr td {
    &.green {
      color: #00bd2d !important;
      border: 1px solid $color-border;
      background-color: rgba(0, 189, 45, 0.16) !important;
    }

    &.red {
      border: 1px solid $color-border;
      background-color: rgba(239, 0, 22, 0.12) !important;
      color: #ef0016 !important;
    }

    &.yellow {
      color: #daa109 !important;
      border: 1px solid $color-border;
      background-color: rgba(255, 186, 0, 0.16) !important;
    }
  }

  // Cell colors
  // Ui overites
  // icons overides
  .sx-folder {
    display: inline-flex;
  }

  // end  icons overides


  .ui.grid>.row:not(.dt-table) {
    padding: 16px 0;
  }

  .ui.link.menu .item {
    transition: all ease 0.4s;
  }

  .ui.link.menu .item:hover,
  .ui.menu .dropdown.item:hover,
  .ui.menu .link.item:hover,
  .ui.menu a.item:hover {
    color: $color-white;
    background-color: #0072bc;

  }

  .ui.pagination.menu {
    min-height: 32px;
    box-shadow: none;
    border-radius: 2px;
    height: 32px;
    border: 1px solid $color-light-grey-3;

    .item {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 700;
      color: $color-blue-2;
      min-width: 32px;
      padding: 5px;
      text-align: center;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 400px) {
        min-width: 26px;
      }

      &:first-child {
        border-radius: 0 2px 2px 0;
      }

      &:last-child {
        border-radius: 2px 0 0 2px;

        &::after {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: rgba(34, 36, 38, .1);
        }
      }
    }

    .first,
    .next,
    .previous,
    .last {
      font-size: 0;
      width: 32px;
      height: 32px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .next {
      transform: rotate(180deg);
      background-size: 5px 8px;
      background-image: url('../images/icons/arrow_pagination-active.svg');

      @media screen and (max-width: 767px) {
        &::before {
          right: 0 !important;
          left: auto !important;
        }
      }

      &.disabled {
        background-image: url('../images/icons/arrow_pagination.svg');

        &:hover {
          background-image: url('../images/icons/arrow_pagination.svg');
        }
      }

      &:hover {
        background-image: url('../images/icons/arrow_pagination-white.svg');
      }
    }

    .previous {
      background-size: 5px 8px;
      background-image: url('../images/icons/arrow_pagination-active.svg');

      &.disabled {
        background-image: url('../images/icons/arrow_pagination.svg');

        &:hover {
          background-image: url('../images/icons/arrow_pagination.svg');
        }
      }

      &:hover {
        background-image: url('../images/icons/arrow_pagination-white.svg');
      }
    }

    .last {
      transform: rotate(180deg);
      background-size: 9px 8px;
      background-image: url('../images/icons/toLast_pagination-active.svg');

      &.disabled {
        background-image: url('../images/icons/toLast_pagination.svg');

        &:hover {
          background-image: url('../images/icons/toLast_pagination.svg');
        }
      }

      &:hover {
        background-image: url('../images/icons/toLast_pagination-white.svg');
      }
    }

    .first {
      background-size: 9px 8px;
      background-image: url('../images/icons/toLast_pagination-active.svg');

      &.disabled {
        background-image: url('../images/icons/toLast_pagination.svg');

        &:hover {
          background-image: url('../images/icons/toLast_pagination.svg');
        }
      }

      &:hover {
        background-image: url('../images/icons/toLast_pagination-white.svg');
      }
    }
  }

  .ui.menu .item:hover,
  .ui.pagination.menu .active.item {
    color: $color-white;
    background-color: #0072bc;
    transition: ease all 0.4s;
  }

  .ui.pagination.menu {
    @media screen and (max-width: 767px) {
      flex-direction: row;
    }
  }

  .ui.pagination.menu .item {
    @media screen and (max-width: 767px) {
      width: auto !important;
    }
  }

  .ui.dropdown .menu>.item:hover,
  .ui.dropdown .menu .active.item {
    color: $color-white;
    background-color: #0072bc;
    transition: ease all 0.4s;
  }

  .load-data {
    display: inline-block;
    padding: 10px 6px;
    color: rgba(0, 0, 0, 0.67);
    font-size: 16px;
    font-weight: 600;
    border-color: #626262;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 2px;
    background-color: #f9fafb;
    margin: 20px 0px;

  }

  .hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    box-shadow: 0 0 2px #755985;
  }

  .hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DDD7E0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  .hvr-sweep-to-top:hover,
  .hvr-sweep-to-top:focus,
  .hvr-sweep-to-top:active {
    color: #010101;
  }

  .hvr-sweep-to-top:hover:before,
  .hvr-sweep-to-top:focus:before,
  .hvr-sweep-to-top:active:before,
    {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  [class*="sixteen wide"].column {
    overflow: auto;
  }

  .dataTables_info,
  .dataTables_length {
    display: inline-block;
  }

  .dataTables_paginate {
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: flex-end;
      padding: 16px 0 0;
    }
  }

  .dataTables_length {
    width: 120px;
    padding-left: 65px;
    position: relative;
    height: 32px;
    vertical-align: bottom;
    display: inline-flex;
    align-items: center;

    label {
      color: rgba(34, 34, 34, 0.54);
      font-family: Roboto !important;
      font-size: 12px !important;
      font-weight: 400;
      line-height: 11px;
    }

    .selection {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .ui.table {
    border-radius: 4px;
    overflow: auto;
    white-space: nowrap;
    border-collapse: collapse;
    border: 0;
  }

  .ui.stackable.menu .item:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34, 36, 38, .1);
  }

  .ui.table thead tr:first-child>th:last-child {
    // border-radius: 0;
  }

  .ui.selection.dropdown {
    min-height: 32px;
    width: 60px;
    border-radius: 2px;
    border: 1px solid $color-light-grey-3;
    background-color: $color-white;
    padding: 8px 25px 8px 9px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-black-2;

    &>.dropdown.icon {
      padding: 0;
      margin: 0;
    }

    .menu {
      @media screen and (max-width: 767px) {
        max-height: 130px;
      }
    }
  }




  .sx-dropdown {
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding: 16px 0 0;
    }
  }

  .sx-table-selection {
    height: 32px !important;
    border-radius: 2px 0 0 2px;
    border: 1px solid $color-light-grey-3 !important;
    background-color: $color-white !important;
    min-width: 120px !important;
    padding-left: 0;

    .default.text {
      color: $color-black-2 !important;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 400;
      // Text style for "Спра!importantвка"
      letter-spacing: 0.43px;
    }

    .dropdown.icon {
      font-size: 0;
      opacity: 1 !important;
      width: 8px !important;
      height: 5px !important;
      right: 12px !important;
      top: 50% !important;
      transform: translateY(-50%);
      background-size: cover;
      background-image: url('../images/icons/arrow-select.svg');
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 400px) {}

  .reload-cont {
    display: inline-flex;
    align-items: center;
    align-self: center;
    margin-right: 24px;

    >div {
      display: flex;

      .dataTables_info {
        white-space: normal;
        opacity: 0.54;
        color: $color-black-2;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 11px;
      }
    }
  }

  .left-controls {
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }
  }

  .sx-reload-table {
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 2px;
    border: 1px solid $color-light-grey-3;
    background-color: $color-white;
    background-image: url('../images/icons/reload.svg');
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 8px;
  }
}

.sx-dataTable {

  .dataTables_wrapper .bottom,
  .dataTables_wrapper .top {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    margin-bottom: 0;
    margin-top: 0;

    .grid {
      margin: 0 -16px !important;
      align-items: flex-end;
      justify-content: flex-end;

      @media screen and (max-width: 767px) {
        flex-direction: column;

      }
    }
  }

  .dataTables_wrapper .top {
    padding-bottom: 16px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin: 0;

      .left-controls {
        padding: 0;
      }
    }

    @media screen and (max-width: 400px) {
      padding: 0 0px 16px;
      margin: 0;
    }
  }



  .dataTables_wrapper .bottom {
    flex-wrap: wrap;
    padding-top: 16px;

    .sx-btn-cont {
      // flex: 1 1 100%;
      padding-bottom: 16px;

      .button {
        margin-left: 12px;

        &:first-of-type {
          margin-left: 0;
        }

        @media screen and(max-width:480px) {
          margin: 0;
          width: 100%;
          margin-bottom: 16px;
        }
      }

      @media screen and(max-width:480px) {
        padding-bottom: 0px;
      }
    }

    .grid {
      @media screen and (max-width: 767px) {
        margin: 0 !important;
      }
    }

    >.grid {
      margin-bottom: 16px !important;
      // flex: 1 1 100%;
      justify-content: space-between;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 16px 0;

      // overflow: hidden !important;
      .left-controls {
        padding: 0;
      }
    }

    @media screen and (max-width: 400px) {
      padding: 16px 0;

    }
  }
}

div.dt-buttons {
  font-size: 0;
  width: 100%;

}

th.dt-center {
  text-align: center !important;
}

.sx-dataTable {
  overflow: hidden;

  .ui.celled.table {
    tr {
      td:first-of-type {
        // width: 60px;
        box-sizing: border-box;
        padding: 13px 19px 12px;

      }
    }
  }
}

.sx-table-w-footer {
  tbody {
    tr:last-of-type {
      td {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0 !important;
          left: 0 !important;
          top: auto !important;
          height: 1px;
          width: 100%;
          background: $color-border;
        }
      }
    }
  }
}