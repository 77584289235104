
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Bold.ttf') format('ttf'),
         url('../fonts/subset-Akrobat-Bold.woff') format('woff'),
         url('../fonts/subset-Akrobat-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Regular.ttf') format('ttf'),
         url('../fonts/subset-Akrobat-Regular.woff') format('woff'),
         url('../fonts/subset-Akrobat-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

