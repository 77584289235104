.sx-content-table {

    border: 1px solid $color-border;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;

    .ui.grid {
        margin: 0;
    }

    .ui.vertical.tabular.menu .item {
        background-color: $color-light-grey-2;
        border-top: 1px solid $color-border;
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.43px;

        &>.angle.right.icon {
            background-image: url(../images/down-arrow-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            font-size: 0;
            width: 18px;
            height: 10px;
            display: inline-block;
            transform: translate(0%, -50%) rotate(270deg);
            transition: ease all 0.3s;

            @media (min-width: 910px) {
                display: none;
            }
        }

        &>.angle.right.sx-icon-modified {
            transform: translate(0%, 20%) rotate(270deg);
        }
    }

    .ui.vertical.tabular.menu .active.item {
        background-color: $color-grey-4;
        border-radius: 0px 0 0 0px !important;
        border: none;
    }

    .ui.vertical.tabular.menu .active.item:nth-child(1) {
        border-radius: 4px 0 0 0px !important;
        display: block;
        cursor: pointer;
        // flex-wrap: wrap;
        // flex-direction: column;
    }

    .ui.vertical.tabular.menu .active.item:last-child {
        border-radius: 0px 0 0 4px !important;
    }

    .ui.vertical.tabular.menu:not(.active) .item {
        border-right: 1px solid $color-border;
        display: block;
        cursor: pointer;
        // flex-wrap: wrap;
        // flex-direction: column;
    }

    .ui.vertical.tabular.menu:not(.active) .item.bolded {
        font-weight: 400;
    }

    .ui.tab.active {
        border-left: none;
        border: none;
        border-radius: 0 4px 4px 0;
        box-shadow: none;
    }

    @media (max-width: 910px) {
        .ui.grid>.stretched.sx-column-modified:not(.row) {
            display: none !important;
        }

        .ui.grid>.stretched.column:not(.row).active {
            display: block !important;
            width: 100% !important;
        }

        .ui.grid>[class*="four wide"].column {
            display: block !important;
        }

        .ui.grid>[class*="four wide"].column.active {
            display: none !important;
        }
    }

    .ui.grid>.stretched.column:not(.row) {
        padding: 0;

    }

    .ui.grid>[class*="four wide"].column {
        padding: 0;
        background-color: $color-light-grey-2;

        @media (max-width: 910px) {
            width: 100% !important;
        }
    }

    .ui.grid>.stretched.column:not(.row)>* {
        margin: 0;
        padding: 32px;
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: 300;
        line-height: 22px;

        @media (min-width: 910px) {
            max-width: 83%;
        }
    }

    .ui.vertical.tabular.menu .item:last-child {

        border-bottom: 1px solid $color-border;
    }

    .ui.menu.fluid,
    .ui.vertical.menu.fluid {
        border-radius: 4px 0 0 4px !important;
        border-right: none;
    }

    .text-heading,
    .text-heading-h3 {
        color: $color-black-2;
        font-family: 'Akrobat';
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
    }

    .text-heading-h3 {
        font-size: 15px;
        font-weight: 300;
        display: block;
    }

    .table-title {
        color: $color-black-2;
        font-family: 'Akrobat';
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0;
        display: inline-flex;
        align-items: flex-end;
        padding-left: 0 !important;
        margin-bottom: 5px;

        @media (max-width: $phone-v) {
            margin-bottom: 12px;
        }
    }

    @media only screen and (max-width: 767px) {

        .ui.grid .ui.stackable.grid,
        .ui.segment:not(.vertical) .ui.stackable.page.grid {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .caption-time {
        opacity: 0.64;
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0.34px;
        margin-bottom: 4px;
        display: block;
    }

    .username {
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: 400;
    }

    .ui.button {
        border-radius: 4px;
        background-color: $color-blue-2;
        color: $color-white;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.4px;
        float: right;
        margin: 0;
        margin-bottom: 20px;
    }

    .ui.form {
        max-width: 352px;

        @media (max-width: 910px) {
            max-width: 100%;
            margin-bottom: 12px;
        }
    }

    .ui.form .fields>.field {
        margin-bottom: 10px;
    }

    .ui.form .fields {
        flex-direction: column;
        margin-bottom: 0px;

        @media (max-width: 910px) {
            margin-left: 0;
            margin-bottom: 12px;
        }

    }

    .ui.form input:not([type]),
    .ui.form input[type=date],
    .ui.form input[type=datetime-local],
    .ui.form input[type=email],
    .ui.form input[type=file],
    .ui.form input[type=number],
    .ui.form input[type=password],
    .ui.form input[type=search],
    .ui.form input[type=tel],
    .ui.form input[type=text],
    .ui.form input[type=time],
    .ui.form input[type=url] {
        border-radius: 2px;
        border: 1px solid $color-light-grey-3;
        color: $color-black-2;
        font-family: ''Roboto'';
        font-size: 14px !important;
        font-weight: 400;
        letter-spacing: 0.4px;
    }

    .ui.form.error .error.message:not(:empty) {
        margin-top: 55px;
    }

    .sx-dataTable .left-controls {
        margin-left: auto;
        padding-right: 0;
    }

    .sx-dataTable .dataTables_wrapper .bottom {
        justify-content: center;
    }

    .ui.form .inline.fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ui.sx-inline-form .inline.fields .field {
        margin-bottom: 0 !important;
        padding-right: 20px;
    }

    .ui.sx-inline-form.form .inline.fields {
        border-bottom: 1px solid $color-border;

        @media (max-width: 910px) {
            flex-wrap: wrap;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .ui.selection.dropdown {
        border: none;
        float: right;
        min-width: auto;

        @media (max-width: 910px) {
            padding-left: 0;
            display: inline-flex;
            min-width: 100px;
        }
    }

    .ui.selection.dropdown>.text {
        float: right;
        font-size: 13px;
        color: rgba(34, 34, 34, 0.8);
    }

    .sx-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-border;

        @media (max-width: 910px) {
            flex-wrap: wrap;

            label {
                width: 100%;
                margin-top: 12px;
            }
        }
    }

    .ui.checkbox input:checked~.box:before,
    .ui.checkbox input:checked~label:before {
        border: 2px solid $color-primary;
    }

    .ui.radio.checkbox .box:after,
    .ui.radio.checkbox label:after {
        top: 2px !important;
        left: 1px !important;
        width: 13px !important;
        height: 13px !important;
        background-color: $color-primary !important;
    }

    .ui.checkbox .box:before,
    .ui.checkbox label:before {
        border: 2px solid $color-primary !important;
    }

    .ui.radio.checkbox .box,
    .ui.radio.checkbox label {
        padding-left: 20px;
    }

    .ui.dropdown .menu .selected.item,
    .ui.dropdown.selected,
    .ui.selection.dropdown .menu>.item {
        font-size: 13px;
    }

    .sx-inline-form {
        width: 100%;
        max-width: 100% !important;

        .fields {
            flex-direction: row !important;
        }

        .inline.fields>label {
            color: $color-black-2;
            font-family: 'Roboto';
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 12px;
            margin-top: 12px;

            @media (max-width: 910px) {
                width: 100%;
            }
        }

        .ui.form .fields>.field {
            margin-bottom: 0 !important;
        }
    }

    .ui.grid>.row {
        border: 1px solid $color-border;
        border-bottom: 0;
        padding: 0;
    }

    .ui.grid>.row:nth-of-type(1) {
        border-radius: 4px 4px 0 0;
    }

    .ui.grid>[class*="equal width"].row>.column,
    .ui[class*="equal width"].grid>.column:not(.row),
    .ui[class*="equal width"].grid>.row>.column {
        padding: 13px 16px;
        display: flex;

    }

    .ui[class*="equal width"].grid>.row:nth-of-type(1)>.column {
        justify-content: space-between;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(2)>.column,
    .ui[class*="equal width"].grid>.row:nth-of-type(3)>.column,
    .ui[class*="equal width"].grid>.row:nth-of-type(4)>.column {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .ui.grid>.row:last-child {
        border-bottom: 1px solid $color-border;
        border-radius: 0 0 4px 4px;
        flex-direction: row;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(4)>.column:nth-of-type(1),
    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(1) {
        border-right: solid 1px $color-border;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(4)>.column:nth-of-type(1) time,
    .ui[class*="equal width"].grid>.row:nth-of-type(4)>.column:nth-of-type(2) time {
        opacity: 0.92;
        color: $color-black-2;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: 400;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(1),
    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(2) {
        padding: 0;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(1) button,
    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(2) button {
        width: 100%;
        margin: 0;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(1) button {
        border-radius: 0 0 0 4px;
    }

    .ui[class*="equal width"].grid>.row:nth-of-type(5)>.column:nth-of-type(2) button {
        border-radius: 0 0 4px 0;
    }

    .ui.padded.grid:not(.vertically):not(.horizontally) {
        max-width: 249px;
        margin-top: 24px !important;
        margin-right: 24px !important;

        @media (max-width: $phone-v) {
            margin-right: 0 !important;
        }

    }

    .ui.empty.circular.label,
    .ui.empty.circular.labels .label {
        padding: 0px !important;
        font-size: 0.757143rem;
        display: inline-flex;
        align-self: center;
    }

    .sx-column-one {
        color: $color-black-2;
        font-family: 'Akrobat';
        font-weight: 400;
        word-break: break-all;
        font-size: 22px;

        &--modified {
            font-family: "Roboto";
            font-size: 13px;
            font-weight: 300;
        }
    }

    .sx-column-two {

        color: $color-black-2;
        font-family: 'Roboto';

        font-weight: 400;

        &--modified {
            opacity: 0.54;
            font-size: 13px;
        }

        i.icon {
            background-image: url(../images/check-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            font-size: 0;
            width: 12px;
            height: 9px;
            display: inline-block;
            transform: rotate(0deg);
        }
    }

    .sx-flex-cont {
        display: flex;
        align-items: center;

        @media (max-width: 910px) {
            flex-wrap: wrap;
        }


        &-right {
            background-color: $color-light-grey-4;
            display: flex;
            width: 100%;
            max-width: 249px;
            justify-content: center;
            height: 304px;
            align-items: center;
            margin-top: 24px;
            flex-direction: column;

            &-img {
                margin-bottom: 16px;
            }

            &-span {
                opacity: 0.54;
                color: $color-black-2;
                font-family: Akrobat;
                font-size: 22px;
                font-weight: 400;
            }
        }
    }

    i.icon.arrow.right:before {
        background-image: url(../images/grey-right-arrow.svg) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        font-size: 0;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        content: '' !important;
        padding: 9px;
        display: block;
    }

    .ui.vertical.pointing.menu .item:after {
        border-top: 1px solid $color-border !important;
        border-right: 1px solid $color-border !important;

        @media (max-width: 910px) {
            display: none;
        }
    }

    .mail-button {
        background-color: transparent;
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 70px;

        @media (max-width: 910px) {
            margin-bottom: 30px;
        }

        & i.icon {
            background-image: url(../images/down-arrow-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            font-size: 0;
            width: 18px;
            height: 10px;
            display: inline-block;
            transform: rotate(90deg);
            transition: ease all 0.3s;

            @media (min-width: 910px) {
                display: none;
            }
        }
    }

    .mail-back-button {
        opacity: 0.54;

        @media (min-width: 910px) {
            display: none;
        }
    }

    @media (max-width: 910px) {
        .sx-dataTable .dataTables_wrapper .top {
            flex-direction: row;
        }
    }



}