.payment-steps {
    display: flex;
    counter-reset: section;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    max-width: 550px;
    padding-bottom: 30px;
    margin: 0 auto;
    width: 100%;
}

.step-container {
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 0;
    margin-right: 10px;
}

.step-container:last-child {
    margin-right: 0;
}

.step-container:not(:last-child):before {
    content: "";
    position: absolute;
    left: 50%;
    top: 19px;
    width: 410px;
    height: 2px;
    background-color: #ababab;
    transform: scaleX(0);
    transform-origin: center left;
    transition: 1s ease;
    z-index: 3;
}

.step-container.visited:not(:last-child):before {
    transform: scaleX(1);
    background-color: $color-blue-2;
}

.step-container:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 19px;
    width: 520px;
    height: 2px;
    background-color: #ababab;
    height: 2px;
    z-index: 2;
}

.step-container:last-child:after {
    background-color: #fff
}

.step-text {
    display: block;
    width: 100%;
    padding-top: 8px;
    font-size: 20px;
    color: $color-black-2;
    opacity: 0.32;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.24px;
}

.step-counter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light-grey-4;
    position: relative;
    border-radius: 50%;
    z-index: 3;
}

.step-container:nth-child(1)>.step-counter:first-child:after {
    left: 10px;
}

.step-container:last-child>.step-counter:first-child:after {
    left: 0px;
    width: 42px;
}

.step-container.visited>.step-counter:after {
    background-color: $color-primary;
}
.step-container.active>.step-counter:after {
    background-color: $color-primary;
}
.step-container.active>.step-text {
    color: $color-primary;
    opacity: 1;
}
.step-counter:before {
    border-radius: 50%;
    font-size: 22px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: section;
    content: counter(section) "";
    transition: .3s ease;
    z-index: 4;
}

.step-container.active.with-delay .step-counter:before {
    transition-delay: .6s
}

.step-counter:after {
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: 16px -5px;
    height: 9px;
    position: absolute;
    background-color: $color-light-grey-4;
    border-radius: 30%;
    z-index: 3;
}

.step-container.active .step-counter:before,
.step-container.visited .step-counter:before {
    background-color: $color-primary;
    color: $color-white;
}

.step-container.visited .step-counter:before {
    color: $color-white;
}

.step-container.visited .step-counter:after {
    right: 0;
}