/* ==========================================================================
   Helper classes
   ========================================================================== */
.center {
	text-align: center!important;
}
.align-left {
    text-align: left!important;
}
.align-right {
    text-align: right!important;
}
.nowrap {
	white-space: nowrap!important;
}
.no-margin {
	margin: 0!important;
}
.hidden {
    display: none !important;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}
.invisible {
    visibility: hidden;
}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}