.sx-filters.ui.tabular {
    margin: 0 -16px !important;
    border: 0 !important;
    width: auto !important;
    max-width: initial !important;
    max-width: none !important;
    min-height: 36px;

    @media screen and (max-width: 767px) {
        margin: 0 -16px !important;
    }

    .item,
    .item.wide {
        padding: 0 16px !important;
        border: 0 !important;

        @media screen and (max-width: 1023px) {
            width: 50% !important;
        }

        @media screen and (max-width:767px) {
            padding: 8px 16px !important;
            margin-bottom: 0 !important;
            width: 100% !important;
        }

        &.active {
            background: none !important;
            border: 0 !important;

            @media screen and (max-width: 767px) {
                padding-bottom: 0 !important;
            }

            .icon.dropdown {
                display: none;
            }

            .sx-close-tab {
                display: block;
            }

            .sx-dropdown-like {
                min-height: 44px;
                margin-bottom: 0;
                align-items: flex-start;
                background-color: $color-light-grey-3 !important;
            }
        }
    }

    .ui.dropdown {
        padding: 8px 38px 8px 10px;
    }

    .ui.dropdown,
    .sx-dropdown-like {
        display: flex;
        width: 100%;
        min-height: 36px;
        border-radius: 2px;
        border: 1px solid $color-light-grey-3;
        background-color: $color-white;
        position: relative;
        align-items: center;
        margin-bottom: 8px;
        max-width: 100%;
        min-width: auto !important;
        @media screen and (max-width:768px){
            margin-bottom: 0!important;
        }
        .dropdown.icon {
            width: 8px;
            height: 5px;
            font-size: 0;
            position: absolute;
            top: 50% !important;
            right: 12px !important;
            opacity: 1 !important;
            transform: translateY(-50%);
            background-size: cover;
            background-image: url('../images/icons/arrow-select.svg');
            background-repeat: no-repeat;
        }

        .sx-tab-item {
            min-height: 36px;
            display: flex;
            width: 100% !important;
            height: 100%;
            padding: 8px 38px 8px 10px !important;
        }

        .text,
        .sx-tab-item {
            color: $color-black-2;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.4px;
            display: flex;
            width: 100%;

        }
    }

    .sx-close-tab {
        width: 10px;
        height: 10px;
        font-size: 0;
        position: absolute;
        top: 50% !important;
        right: 12px !important;
        opacity: 1 !important;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-size: cover;
        background-image: url('../images/icons/close-icon.svg');
        background-repeat: no-repeat;
    }


    .sx-close-tab {
        display: none;
    }
}

.sx-tab-attached {
    display: none;
    border: 0;

    &.active {
        display: block;

        @media screen and (max-width: 767px) {
            margin-bottom: 8px;
        }
    }

    .grid {
        border-radius: 2px 0 2px 2px;
        border: 1px solid $color-light-grey-3;
        background-color: $color-light-grey-3;
        padding: 24px;

        @media screen and (max-width: 767px) {
            padding: 8px !important;
        }
    }

    label {
        color: $color-black-2;
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        letter-spacing: 0.46px;
        margin-bottom: 12px !important;
    }

    input[type="text"] {
        height: 36px;
        border-radius: 2px;
        border: 1px solid $color-light-grey-3;
        background-color: $color-white;
        color: rgba($color-black-2, 0.48);
        font-family: Roboto;
        font-size: 14px !important;
        font-weight: 400;
        // Text style for "Избери"
        letter-spacing: 0.4px;

        @media (max-width: 910px) {
            font-size: 16px !important;
        }
    }

    .fields {
        margin: 0 !important;
    }

    .two .fields {
        margin: 0 -8px !important;
        flex-wrap: wrap;

        .field {
            flex: auto !important;
            width: auto !important;
        }
    }

    .field {
        padding: 0 8px !important;
        margin-bottom: 8px !important;

        @media screen and (max-width: 767px) {
            // padding: 0 !important;
        }
    }

    .calendar .ui.input {
        display: flex;
        align-items: center;

        .icon.calendar {
            font-size: 0;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50% !important;
            right: 12px !important;
            opacity: 1 !important;
            transform: translateY(-50%);
            background-size: cover;
            background-image: url('../images/icons/calendar.svg');
            background-repeat: no-repeat;
        }

        span {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            // Text style for "от"
            color: rgba($color-black-2, 0.54);
            letter-spacing: 0.4px;
            display: inline-block;
            margin-right: 12px !important;

            @media (max-width: 910px) {
                font-size: 16px;
            }
        }

    }
}