.sx-main-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .column {
        max-width: 450px;
    }

    body>.grid {
        height: 100%;
    }

    .sx-message {
        text-align: right;
        max-width: 186px;
        margin-left: auto;
        border: 0;
        padding: 0;
        background: none;
        box-shadow: none;
    }

    .ui.teal.header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 48px;
    }

    .ui.header>.site-logo>.image:not(.icon),
    .ui.header>img {
        max-width: 198px;
        min-width: 100%;
        margin-bottom: 18px;
    }

    .content {
        color: $color-black-2;
        font-family: Akrobat;
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 0.86px;
    }

    .ui.teal.button {
        background-color: $color-red;
        color: $color-white;
        font-family: "Akrobat";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.55px;
        transition: all ease 0.4s;

        &:hover {
            background-color: $color-primary;
        }
    }

    .ui.message>:last-child {
        transition: all ease 0.4s;

        &:hover {
            color: $color-red;
        }
    }

    .ui[class*="middle aligned"].grid>.column:not(.row) {
        @media (max-width: 910px) {
            margin-bottom: 20px;
        }
    }
}

.ui[class*="center aligned"].grid {
    margin: 0 10px;
}