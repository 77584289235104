/* Typography */
.text {




    .text > :last-child {
        margin-bottom: 0;
    }
    /* floating images */
    img {
        margin-bottom: 1.25em;
        max-width: 100%;
        .center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    img[style*='float: left'] {
        margin: 0.5em 1.25em 1.25em 0;
        max-width: 50%;
    }
    img[style*='float: right'] {
        margin: 0.5em 0 1.25em 1.25em;
        max-width: 50%;
    }
    @media(max-width: $phone-v) {
        img[style*='float: left'],
        img[style*='float: right'] {
            margin: 0 0em 1.25em 0;
            max-width: 100%;
            float: none!important;
        }
    }
    /* end of floating images */

    /* Video embed */
    .video-embed-wrapper {
    	width: 50%;
    	margin: 0.5em auto 1.25em auto;
        &--left {
            float: left;
            margin-right: 1.25em;
        }
        &--right {
            float: right;
            margin-left: 1.25em;
        }
        &--full {
        	width: 100%;
        }
    }

    .video-embed {
    	position: relative;
    	padding-bottom: 56.25%; /* 16:9 */
    	height: 0;
    	margin: 0 auto;
        iframe {
        	position: absolute;
        	top: 0;
        	left: 0;
        	width: 100%;
        	height: 100%;
        }
    }
    /* end of Video embed */
}